/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, FC} from 'react'
import {toAbsoluteUrl} from '../../../../_designereyes/helpers'
import {PageTitle} from '../../../../_designereyes/layout/core'
import {useAuth} from '../../../modules/auth'
import axios from 'axios'
import {Tooltip} from 'bootstrap'

import {API_URL} from '../../../modules/auth/core/_requests'

const ProductDataReportPage: FC = () => {
  const {currentUser} = useAuth()
  const [searchCriterion, setSearchCriterion] = useState<string>('UPC')
  const [productValues, setProductValues] = useState<string>('')
  const [message, setMessage] = useState<string>('')

  useEffect(() => {
    const tooltipTriggerList = Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
        html: true, // Enable HTML content in tooltips
      })
    })
  }, [])

  interface FieldOption {
    label: string
    value: string
    checked: boolean
    description: string
  }

  // Master Fields
  const [masterFields, setMasterFields] = useState<FieldOption[]>([
    {
      label: 'UPC',
      value: 'upc',
      checked: false,
      description:
        '<b>Unique product code</b> (UPC), a 12-digit identifier primarily used in the United States and Canada. Some UPCs may also be EAN codes, which are 13 digits and commonly used in Europe.',
    },
    {
      label: 'SKU 1(NAV)',
      value: 'sku_1',
      checked: false,
      description:
        'Generated SKU assigned when a product is created in Microsoft Dynamics NAV. <br><b>Formula:</b> {Incrementing number}',
    },
    {
      label: 'SKU 2(EC)',
      value: 'sku_2',
      checked: false,
      description:
        'Custom SKU generated when creating a product in Microsoft Dynamics NAV, specifically used for EC AMZ Amazon account.<br><b>Formula:</b> {Incrementing number}',
    },
    {
      label: 'SKU 3(VSC)',
      value: 'sku_3',
      checked: false,
      description:
        'Custom SKU generated when creating a product in Microsoft Dynamics NAV, specifically used for EC AMZ Amazon account.<br><b>Formula:</b> {Incrementing number}',
    },
    {
      label: 'ASIN',
      value: 'asin',
      checked: false,
      description:
        'Amazon Standard Identification Number, a unique 10-character identifier for products on Amazon.',
    },
    {
      label: 'Model',
      value: 'model',
      checked: false,
      description: 'The model name or number of the product.<br><b>EX:OJ9010</b>',
    },
    {
      label: 'Size Code',
      value: 'size_code',
      checked: false,
      description:
        'Code representing the product’s size. For eyewear its usually the lens width in mm.',
    },
    {
      label: 'Color Code',
      value: 'color_code',
      checked: false,
      description: 'Code representing the product’s size.<br><b>EX: b623</b>',
    },
    {
      label: 'Category',
      value: 'category',
      checked: false,
      description:
        'Product category, classifying the item into a specific group.<br><b>EX: eyewear</b>',
    },
    {
      label: 'Product Type',
      value: 'product_type',
      checked: false,
      description: 'The type of product or its classification.<br><b>EX: sunglasses</b>',
    },
    {
      label: 'Collection',
      value: 'collection',
      checked: false,
      description: 'The collection or season the product was released in<br><b>EX: 2021 M1</b>',
    },
    {
      label: 'Vendor',
      value: 'vendor_name',
      checked: false,
      description: 'Products Vendor<br><b>EX:Luxottica</b>',
    },
    {
      label: 'Brand',
      value: 'brand',
      checked: false,
      description: 'Products Brand<br><b>EX: Costa Del Mar</b>',
    },
    {
      label: 'Brand Code',
      value: 'brand_code',
      checked: false,
      description: 'Brand code<br> <b>EX: "RB", "6S"</b>',
    },
    {
      label: 'Color',
      value: 'color',
      checked: false,
      description:
        'The color of the product. <br>For eyewear color formula = <b>{frame_color} | {lens_color}</b><br>For other products its just the color',
    },
    {
      label: 'Gender',
      value: 'gender',
      checked: false,
      description: 'The gender category for which the product is designed.',
    },
    {
      label: 'Size',
      value: 'size',
      checked: false,
      description:
        'The size of the product. For Eyewear size = <b>{lens_weight}-{bridge_width}-{temple_length}</b><br>For AFA its usaully S,M,L ect...',
    },
    {
      label: 'Name',
      value: 'name',
      checked: false,
      description:
        'Name of product.<br><b>Formula:{style_name} {model} {brand} {product Type}<br><b>EX: FT0497 TOM FORD SUNGLASSES</b>',
    },
    {
      label: 'Description',
      value: 'long_description',
      checked: false,
      description: 'Products Description(coming soon)',
    },
  ])

  // Category Specific Fields
  const [categorySpecificFields, setCategorySpecificFields] = useState<FieldOption[]>([
    {
      label: 'Lens Width',
      value: 'lens_width',
      checked: false,
      description: 'Width of the lens in millimeters.<br><b>EX: 57</b>',
    },
    {
      label: 'Bridge Width',
      value: 'bridge_width',
      checked: false,
      description: 'Width of the bridge in millimeters.<br><b>EX: 15</b>',
    },
    {
      label: 'Temple Length',
      value: 'temple_length',
      checked: false,
      description: 'Length of the temple (arm of the frame) in millimeters.<br><b>EX: 140</b>',
    },
    {
      label: 'Frame Color',
      value: 'frame_color',
      checked: false,
      description: 'Color of the frame.<br><b>EX: SHINY ABALONE</b>',
    },
    {
      label: 'Frame Material',
      value: 'frame_material',
      checked: false,
      description: 'Material of the frame.<br><b>EX: ACETATE</b>',
    },
    {
      label: 'Lens Color',
      value: 'lens_color',
      checked: false,
      description: 'Color of the lens.<br><b>EX: GRAY</b>',
    },
    {
      label: 'Lens Color Code',
      value: 'lens_color_code',
      checked: false,
      description: 'Color code of the lens.<br><b>EX: G15</b>',
    },
    {
      label: 'Lens Material',
      value: 'lens_material',
      checked: false,
      description: 'Material of the lens.<br><b>EX: Polycarbonate</b>',
    },
    {
      label: 'Shape',
      value: 'shape',
      checked: false,
      description: 'Shape of the eyewear frame.<br><b>EX: Rectangle</b>',
    },
    {
      label: 'Polarized',
      value: 'polarized',
      checked: false,
      description: 'Indicates if the lenses are polarized.<br><b>EX: Yes</b>',
    },
    {
      label: 'Rxable',
      value: 'rxable',
      checked: false,
      description: 'Indicates if the eyewear is prescription compatible.<br><b>EX: Yes</b>',
    },
  ])

  // Financial Fields
  const [financialFields, setFinancialFields] = useState<FieldOption[]>([
    {
      label: 'FIFO Avg Cost',
      value: 'fifo_avg_cost',
      checked: false,
      description:
        'Average cost of inventory items calculated using the FIFO (First-In, First-Out) method.<br><b>EX: $12.34</b>',
    },
    {
      label: 'Weighted Avg Cost',
      value: 'weighted_avg_cost',
      checked: false,
      description:
        'Average cost of inventory items weighted by quantity purchased at each cost.<br><b>EX: $10.50</b>',
    },
    {
      label: 'FIFO Cost',
      value: 'fifo_cost',
      checked: false,
      description:
        'Cost of the oldest inventory item available, as per FIFO method.<br><b>EX: $11.00</b>',
    },
    {
      label: 'Total Inventory Cost',
      value: 'total_inventory_cost',
      checked: false,
      description: 'Total cost of the inventory on hand.<br><b>EX: $5,000.00</b>',
    },
    {
      label: 'Direct Cost',
      value: 'direct_cost',
      checked: false,
      description:
        'Direct cost associated with the product, excluding indirect expenses.<br><b>EX: $8.75</b>',
    },
    {
      label: 'Total Cost',
      value: 'total_cost',
      checked: false,
      description:
        'Total cost including direct and indirect costs for the product.<br><b>EX: $15.00</b>',
    },
    {
      label: 'Inventory',
      value: 'inventory',
      checked: false,
      description: 'Quantity of the product currently in stock.<br><b>EX: 250 units</b>',
    },
    {
      label: 'Map Price',
      value: 'map_price',
      checked: false,
      description: 'Minimum Advertised Price as set by the manufacturer.<br><b>EX: $49.99</b>',
    },
    {
      label: 'MSRP Price',
      value: 'msrp',
      checked: false,
      description: "Manufacturer's Suggested Retail Price.<br><b>EX: $59.99</b>",
    },
    {
      label: 'Wholesale Price',
      value: 'wholesale_price',
      checked: false,
      description:
        'Price at which the product is sold to retailers or distributors.<br><b>EX: $30.00</b>',
    },
    {
      label: 'Minimum AMZ Price',
      value: 'min_price',
      checked: false,
      description: 'Minimum price allowed for selling the product on Amazon.<br><b>EX: $45.00</b>',
    },
    {
      label: 'Minimum AMZ Price(MAP override)',
      value: 'final_min_price',
      checked: false,
      description: 'Minimum Amazon price with MAP override applied.<br><b>EX: $55.00</b>',
    },
    {
      label: 'Minimum Profit Margin',
      value: 'margin_amount',
      checked: false,
      description: 'Minimum profit margin required on the product.<br><b>EX: 20%</b>',
    },
    {
      label: 'Minimum Referral Fee',
      value: 'referral_fee',
      checked: false,
      description: 'Minimum fee paid to Amazon for referring a sale.<br><b>EX: $1.50</b>',
    },
    {
      label: 'Amazon Storage Fee',
      value: 'storage_fee',
      checked: false,
      description:
        'Fee charged by Amazon for storing inventory in fulfillment centers.<br><b>EX: $0.75 per unit</b>',
    },
    {
      label: 'Pick and Pack Fee',
      value: 'pick_and_pack_fee',
      checked: false,
      description:
        'Fee charged by Amazon for picking and packing orders.<br><b>EX: $2.00 per unit</b>',
    },
  ])

  const [imagesFields, setImagesFields] = useState<FieldOption[]>([
    {
      label: 'Image URL 1',
      value: 'image_url1',
      checked: false,
      description: 'URL of the first image for the product.',
    },
    {
      label: 'Image URL 2',
      value: 'image_url2',
      checked: false,
      description: 'URL of the second image for the product.',
    },
    {
      label: 'Image URL 3',
      value: 'image_url3',
      checked: false,
      description: 'URL of the third image for the product.',
    },
  ])

  const handleFieldChange = (
    fieldType: 'master' | 'categorySpecific' | 'financial' | 'images',
    index: number
  ) => {
    const updateFields = (
      fields: FieldOption[],
      setFields: React.Dispatch<React.SetStateAction<FieldOption[]>>
    ) => {
      const newFields = [...fields]
      newFields[index].checked = !newFields[index].checked
      setFields(newFields)
    }

    if (fieldType === 'master') {
      updateFields(masterFields, setMasterFields)
    } else if (fieldType === 'categorySpecific') {
      updateFields(categorySpecificFields, setCategorySpecificFields)
    } else if (fieldType === 'financial') {
      updateFields(financialFields, setFinancialFields)
    } else if (fieldType === 'images') {
      updateFields(imagesFields, setImagesFields)
    }
  }

  const handleSelectAll = (
    fieldType: 'master' | 'categorySpecific' | 'financial' | 'images',
    selectAll: boolean
  ) => {
    const updateFields = (
      fields: FieldOption[],
      setFields: React.Dispatch<React.SetStateAction<FieldOption[]>>
    ) => {
      const newFields = fields.map((field) => ({...field, checked: selectAll}))
      setFields(newFields)
    }

    if (fieldType === 'master') {
      updateFields(masterFields, setMasterFields)
    } else if (fieldType === 'categorySpecific') {
      updateFields(categorySpecificFields, setCategorySpecificFields)
    } else if (fieldType === 'financial') {
      updateFields(financialFields, setFinancialFields)
    } else if (fieldType === 'images') {
      // **Handle images**
      updateFields(imagesFields, setImagesFields)
    }
  }

  const handleDownload = async () => {
    setMessage('Processing...')

    // Gather selected fields
    const selectedFields = {
      master: masterFields.filter((field) => field.checked).map((field) => field.value),
      categorySpecific: categorySpecificFields
        .filter((field) => field.checked)
        .map((field) => field.value),
      financial: financialFields.filter((field) => field.checked).map((field) => field.value),
      images: imagesFields.filter((field) => field.checked).map((field) => field.value), // **Include images**
    }

    // Get the product values from the textarea
    const products = productValues
      .split('\n')
      .map((value) => value.trim())
      .filter(Boolean)

    // Prepare the payload
    const payload = {
      searchCriterion,
      products,
      selectedFields,
    }

    try {
      // Send a POST request to the backend
      const response = await axios.post(`${API_URL}/api/product-data`, payload, {
        responseType: 'blob', // Important for handling binary data
      })

      // Read counts from headers (ensure your backend sends these headers)
      const productsFound = response.headers['x-products-found']
      const productsSearched = response.headers['x-products-searched']

      // Create a URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'product_data.xlsx') // or any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()

      // Set message
      if (productsFound && productsSearched) {
        if (parseInt(productsFound) === 0) {
          setMessage('No items found in database.')
        } else {
          setMessage(`Found ${productsFound}/${productsSearched} ${searchCriterion}s`)
        }
      } else {
        setMessage('Download complete.')
      }
    } catch (error) {
      console.error('Error downloading the file:', error)
      setMessage('An error occurred during download.')
      // Handle error appropriately
    }
  }

  return (
    <div className='card m-1 text-light'>
      <div className='card-header'>
        <h2 className='card-title mb-0'>Select Your Fields</h2>
      </div>
      <div className='card-body'>
        {/* Radio Buttons for Search Criteria */}
        <div className='my-3'>
          <label className='form-label text-white'>Select Search Criterion:</label>
          <div>
            <div className='form-check form-check-inline m-2'>
              <input
                className='form-check-input'
                type='radio'
                name='searchCriterion'
                id='searchCriterionUPC'
                value='UPC'
                checked={searchCriterion === 'UPC'}
                onChange={(e) => setSearchCriterion(e.target.value)}
              />
              <label className='form-check-label text-white' htmlFor='searchCriterionUPC'>
                UPC
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                name='searchCriterion'
                id='searchCriterionSKU1'
                value='SKU 1'
                checked={searchCriterion === 'SKU 1'}
                onChange={(e) => setSearchCriterion(e.target.value)}
              />
              <label className='form-check-label text-white' htmlFor='searchCriterionSKU1'>
                SKU 1(NAV)
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                name='searchCriterion'
                id='searchCriterionSKU2'
                value='SKU 2'
                checked={searchCriterion === 'SKU 2'}
                onChange={(e) => setSearchCriterion(e.target.value)}
              />
              <label className='form-check-label text-white' htmlFor='searchCriterionSKU2'>
                SKU 2(EC)
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                name='searchCriterion'
                id='searchCriterionSKU3'
                value='SKU 3'
                checked={searchCriterion === 'SKU 3'}
                onChange={(e) => setSearchCriterion(e.target.value)}
              />
              <label className='form-check-label text-white' htmlFor='searchCriterionSKU3'>
                SKU 3(VSC)
              </label>
            </div>

            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                name='searchCriterion'
                id='searchCriterionASIN'
                value='ASIN'
                checked={searchCriterion === 'ASIN'}
                onChange={(e) => setSearchCriterion(e.target.value)}
              />
              <label className='form-check-label text-white' htmlFor='searchCriterionASIN'>
                ASIN
              </label>
            </div>
          </div>
        </div>
        {/* Input Section */}
        <div className='my-4'>
          <label htmlFor='productValues' className='form-label text-white'>
            Enter Product Values (one per line):
          </label>
          <textarea
            className='form-control'
            id='productValues'
            rows={5}
            value={productValues}
            onChange={(e) => setProductValues(e.target.value)}
          ></textarea>
        </div>
        {/* Input Section End */}

        {/* Field Selection Sections */}
        <div className='my-4'>
          <label className='form-label text-white'>Select Fields to Include:</label>

          {/* Accordion Component */}
          <div className='accordion' id='fieldSelectionAccordion'>
            {/* Master Fields Accordion Item */}
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingMaster'>
                <button
                  className='accordion-button bg-light'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseMaster'
                  aria-expanded='true'
                  aria-controls='collapseMaster'
                >
                  Master Fields
                </button>
              </h2>
              <div
                id='collapseMaster'
                className='accordion-collapse collapse show'
                aria-labelledby='headingMaster'
                data-bs-parent='#fieldSelectionAccordion'
              >
                <div className='accordion-body'>
                  {/* Select All/Deselect All Buttons */}
                  <div className='my-3'>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary me-2'
                      onClick={() => handleSelectAll('master', true)}
                    >
                      Select All
                    </button>
                    <button
                      type='button'
                      className='btn btn-sm btn-secondary'
                      onClick={() => handleSelectAll('master', false)}
                    >
                      Deselect All
                    </button>
                  </div>
                  {/* Checkbox Grid */}
                  <div className='row'>
                    {masterFields.map((field, index) => (
                      <div className='col-md-3' key={field.value}>
                        <div className='form-check my-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id={`masterField-${field.value}`}
                            checked={field.checked}
                            onChange={() => handleFieldChange('master', index)}
                          />
                          <label
                            className='form-check-label text-white'
                            htmlFor={`masterField-${field.value}`}
                          >
                            {field.label}
                            <i
                              className='bi bi-question-circle ms-2'
                              data-bs-toggle='tooltip'
                              data-bs-placement='right'
                              title={field.description}
                            ></i>
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Category Specific Fields Accordion Item */}
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingCategory'>
                <button
                  className='accordion-button bg-light collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseCategory'
                  aria-expanded='false'
                  aria-controls='collapseCategory'
                >
                  Category Specific Fields
                </button>
              </h2>
              <div
                id='collapseCategory'
                className='accordion-collapse collapse'
                aria-labelledby='headingCategory'
                data-bs-parent='#fieldSelectionAccordion'
              >
                <div className='accordion-body'>
                  {/* Select All/Deselect All Buttons */}
                  <div className='my-3'>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary me-2'
                      onClick={() => handleSelectAll('categorySpecific', true)}
                    >
                      Select All
                    </button>
                    <button
                      type='button'
                      className='btn btn-sm btn-secondary'
                      onClick={() => handleSelectAll('categorySpecific', false)}
                    >
                      Deselect All
                    </button>
                  </div>
                  {/* Checkbox Grid */}
                  <div className='row'>
                    {categorySpecificFields.map((field, index) => (
                      <div className='col-md-3' key={field.value}>
                        <div className='form-check my-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id={`categoryField-${field.value}`}
                            checked={field.checked}
                            onChange={() => handleFieldChange('categorySpecific', index)}
                          />
                          <label
                            className='form-check-label text-white'
                            htmlFor={`categoryField-${field.value}`}
                          >
                            {field.label}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {/* **New: Images Fields Accordion Item** */}
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingImages'>
                <button
                  className='accordion-button bg-light collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseImages'
                  aria-expanded='false'
                  aria-controls='collapseImages'
                >
                  Images Fields
                </button>
              </h2>
              <div
                id='collapseImages'
                className='accordion-collapse collapse'
                aria-labelledby='headingImages'
                data-bs-parent='#fieldSelectionAccordion'
              >
                <div className='accordion-body'>
                  {/* Select All/Deselect All Buttons */}
                  <div className='my-3'>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary me-2'
                      onClick={() => handleSelectAll('images', true)}
                    >
                      Select All
                    </button>
                    <button
                      type='button'
                      className='btn btn-sm btn-secondary'
                      onClick={() => handleSelectAll('images', false)}
                    >
                      Deselect All
                    </button>
                  </div>
                  {/* Checkbox Grid */}
                  <div className='row'>
                    {imagesFields.map((field, index) => (
                      <div className='col-md-4' key={field.value}>
                        <div className='form-check my-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id={`imagesField-${field.value}`}
                            checked={field.checked}
                            onChange={() => handleFieldChange('images', index)}
                          />
                          <label
                            className='form-check-label text-white'
                            htmlFor={`imagesField-${field.value}`}
                          >
                            {field.label}
                            <i
                              className='bi bi-question-circle ms-2'
                              data-bs-toggle='tooltip'
                              data-bs-placement='right'
                              title={field.description}
                            ></i>
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {currentUser?.decoded?.role === 'admin' && (
              <div className='accordion-item'>
                <h2 className='accordion-header' id='headingFinancial'>
                  <button
                    className='accordion-button bg-light collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseFinancial'
                    aria-expanded='false'
                    aria-controls='collapseFinancial'
                  >
                    Financial Fields
                  </button>
                </h2>
                <div
                  id='collapseFinancial'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingFinancial'
                  data-bs-parent='#fieldSelectionAccordion'
                >
                  <div className='accordion-body'>
                    {/* Select All/Deselect All Buttons */}
                    <div className='my-3'>
                      <button
                        type='button'
                        className='btn btn-sm btn-primary me-2'
                        onClick={() => handleSelectAll('financial', true)}
                      >
                        Select All
                      </button>
                      <button
                        type='button'
                        className='btn btn-sm btn-secondary'
                        onClick={() => handleSelectAll('financial', false)}
                      >
                        Deselect All
                      </button>
                    </div>
                    {/* Checkbox Grid */}
                    <div className='row'>
                      {financialFields.map((field, index) => (
                        <div className='col-md-3' key={field.value}>
                          <div className='form-check my-3'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id={`financialField-${field.value}`}
                              checked={field.checked}
                              onChange={() => handleFieldChange('financial', index)}
                            />
                            <label
                              className='form-check-label text-white'
                              htmlFor={`masterField-${field.value}`}
                            >
                              {field.label}
                              <i
                                className='bi bi-question-circle ms-2'
                                data-bs-toggle='tooltip'
                                data-bs-placement='right'
                                data-bs-html='true'
                                title={field.description}
                              ></i>
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* Financial Fields Accordion Item */}
          </div>
        </div>

        <div className='text-center my-5'>
          <button type='button' className='btn btn-success my-3' onClick={handleDownload}>
            Download to Excel
          </button>
          {message && (
            <div className='alert alert-info mt-3' role='alert'>
              {message}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const ProductDataReportWrapper: FC = () => {
  return (
    <>
      <PageTitle>Product Data Puller</PageTitle>
      <ProductDataReportPage />
    </>
  )
}
