import React, {useState, FC} from 'react'
import {PageTitle} from '../../../../_designereyes/layout/core'
import axios from 'axios'
import {API_URL} from '../../../modules/auth/core/_requests'

const AmzMasterfileGeneratorPage: FC = () => {
  const [searchCriterion, setSearchCriterion] = useState<string>('UPC')
  const [productValues, setProductValues] = useState<string>('')
  const [productCategory, setProductCategory] = useState<string>('Eyewear')

  const handleDownload = async () => {
    const products = productValues
      .split('\n')
      .map((value) => value.trim())
      .filter(Boolean)
    const payload = {searchCriterion, products, productCategory}

    try {
      const response = await axios.post(`${API_URL}/api/amz-masterfile`, payload, {
        responseType: 'blob',
      })

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'eyewear-masterfile.xlsx')
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      console.error('Error downloading the file:', error)
    }
  }

  return (
    <div className='card m-1 text-light'>
      <div className='card-header'>
        <h2 className='card-title mb-0'>Amazon Masterfile Generator</h2>
      </div>
      <div className='card-body'>
        <div className='my-3'>
          <label className='form-label text-white'>Select Search Criterion:</label>
          <div>
            {['UPC', 'NAV SKU', 'EC SKU', 'ASIN'].map((criterion) => (
              <div className='form-check form-check-inline m-2' key={criterion}>
                <input
                  className='form-check-input'
                  type='radio'
                  name='searchCriterion'
                  value={criterion}
                  checked={searchCriterion === criterion}
                  onChange={(e) => setSearchCriterion(e.target.value)}
                />
                <label className='form-check-label text-white'>{criterion}</label>
              </div>
            ))}
          </div>
        </div>
        <div className='my-4'>
          <label htmlFor='productValues' className='form-label text-white'>
            Enter Product Values (one per line):
          </label>
          <textarea
            className='form-control'
            id='productValues'
            rows={5}
            value={productValues}
            onChange={(e) => setProductValues(e.target.value)}
          />
        </div>
        <div className='my-3'>
          <label className='form-label text-white'>Select Product Category:</label>
          {['Eyewear', 'T-shirts', 'Jewelry'].map((category) => (
            <div className='form-check form-check-inline m-2' key={category}>
              <input
                className='form-check-input'
                type='radio'
                name='productCategory'
                value={category}
                checked={productCategory === category}
                onChange={(e) => setProductCategory(e.target.value)}
              />
              <label className='form-check-label text-white'>{category}</label>
            </div>
          ))}
        </div>
        <div className='text-center my-5'>
          <button type='button' className='btn btn-success my-3' onClick={handleDownload}>
            Download to Excel
          </button>
        </div>
      </div>
    </div>
  )
}

export const AmzMasterfileGeneratorWrapper: FC = () => {
  return (
    <>
      <PageTitle>Amazon Masterfile Generator</PageTitle>
      <AmzMasterfileGeneratorPage />
    </>
  )
}
