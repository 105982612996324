import React, {FC, useState} from 'react'
import axios from 'axios'
import {API_URL} from '../../../../../../../../app/modules/auth/core/_requests'

interface ReportData {
  totalAsins: number
  successfulAsins: number
  failedAsins: number
  titlesExceedingLength: number
  descriptionsExceedingLength: number
  bulletPointsQty: number
  bulletsLengthOptimized: number
  brandStoryIncluded: number
  aplusContentIncluded: number
  imagesQtyOptimized: number
  videoShortIncluded: number
  primeAvailable: number
  customerReviewsOptimized: number
  productRatingOptimized: number
}

interface InputCardProps {
  setReportData: (data: ReportData) => void
}

const InputCard: FC<InputCardProps> = ({setReportData}) => {
  // State variables
  const [asinsText, setAsinsText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState('')

  // Configuration variables with default values
  const [minTitleLength, setMinTitleLength] = useState<number>(140)
  const [minDescLength, setMinDescLength] = useState<number>(1000)
  const [minBulletQty, setMinBulletQty] = useState<number>(5)
  const [minBulletLength, setMinBulletLength] = useState<number>(140)
  const [minImages, setMinImages] = useState<number>(6)
  const [minReviews, setMinReviews] = useState<number>(30)
  const [minRating, setMinRating] = useState<number>(4.5)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const asins = asinsText
      .split('\n')
      .map((asin) => asin.trim())
      .filter((asin) => asin)

    if (asins.length === 0) {
      alert('Please enter at least one ASIN.')
      return
    }

    try {
      setIsLoading(true)
      setMessage('Processing ASINs...')

      const response = await axios.post(
        `${API_URL}/retail-readiness-report/process`,
        {
          asins,
          minTitleLength,
          minDescLength,
          minBulletQty,
          minBulletLength,
          minImages,
          minReviews,
          minRating,
        },
        {
          responseType: 'json',
        }
      )

      const {excelFile, reportSummary} = response.data

      // Decode the base64 Excel file and trigger download
      const byteCharacters = atob(excelFile)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Retail_Readiness_Report.xlsx')
      document.body.appendChild(link)
      link.click()
      link.remove()

      setMessage('Report downloaded successfully.')

      // Update report data with actual data from backend
      setReportData(reportSummary)
    } catch (error) {
      console.error('Error processing ASINs:', error)
      alert('An error occurred while processing ASINs.')
      setMessage('An error occurred.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='card m-1'>
      <div className='card-header'>
        <h2 className='card-title mb-0'>Add ASINs & Config</h2>
      </div>
      <div className='card-body'>
        <form onSubmit={handleSubmit}>
          <div className='mb-4'>
            <label htmlFor='asinsTextarea' className='form-label'>
              ASINs (one per line):
            </label>
            <textarea
              id='asinsTextarea'
              className='form-control'
              value={asinsText}
              onChange={(e) => setAsinsText(e.target.value)}
              placeholder='Enter ASINs, one per line'
              rows={5}
              required
            />
          </div>
          <div className='mb-4'>
            <h3>Configuration</h3>
            <div className='row'>
              <div className='col-md-6 mb-3'>
                <label htmlFor='minTitleLength' className='form-label'>
                  Minimum Title Length
                </label>
                <input
                  id='minTitleLength'
                  type='number'
                  className='form-control'
                  value={minTitleLength}
                  onChange={(e) => setMinTitleLength(parseInt(e.target.value) || 0)}
                  required
                />
              </div>
              <div className='col-md-6 mb-3'>
                <label htmlFor='minDescLength' className='form-label'>
                  Minimum Description Length
                </label>
                <input
                  id='minDescLength'
                  type='number'
                  className='form-control'
                  value={minDescLength}
                  onChange={(e) => setMinDescLength(parseInt(e.target.value) || 0)}
                  required
                />
              </div>
              <div className='col-md-6 mb-3'>
                <label htmlFor='minBulletQty' className='form-label'>
                  Minimum Bullet Quantity
                </label>
                <input
                  id='minBulletQty'
                  type='number'
                  className='form-control'
                  value={minBulletQty}
                  onChange={(e) => setMinBulletQty(parseInt(e.target.value) || 0)}
                  required
                />
              </div>
              <div className='col-md-6 mb-3'>
                <label htmlFor='minBulletLength' className='form-label'>
                  Minimum Bullet Length
                </label>
                <input
                  id='minBulletLength'
                  type='number'
                  className='form-control'
                  value={minBulletLength}
                  onChange={(e) => setMinBulletLength(parseInt(e.target.value) || 0)}
                  required
                />
              </div>
              <div className='col-md-6 mb-3'>
                <label htmlFor='minImages' className='form-label'>
                  Minimum Number of Images
                </label>
                <input
                  id='minImages'
                  type='number'
                  className='form-control'
                  value={minImages}
                  onChange={(e) => setMinImages(parseInt(e.target.value) || 0)}
                  required
                />
              </div>
              <div className='col-md-6 mb-3'>
                <label htmlFor='minReviews' className='form-label'>
                  Minimum Number of Reviews
                </label>
                <input
                  id='minReviews'
                  type='number'
                  className='form-control'
                  value={minReviews}
                  onChange={(e) => setMinReviews(parseInt(e.target.value) || 0)}
                  required
                />
              </div>
              <div className='col-md-6 mb-3'>
                <label htmlFor='minRating' className='form-label'>
                  Minimum Rating
                </label>
                <input
                  id='minRating'
                  type='number'
                  className='form-control'
                  step='0.1'
                  value={minRating}
                  onChange={(e) => setMinRating(parseFloat(e.target.value) || 0)}
                  required
                />
              </div>
            </div>
          </div>
          <div className='mb-4'>
            <button type='submit' className='btn btn-primary' disabled={isLoading}>
              {isLoading ? (
                <>
                  <span
                    className='spinner-border spinner-border-sm me-2'
                    role='status'
                    aria-hidden='true'
                  ></span>
                  Processing...
                </>
              ) : (
                'Generate Report'
              )}
            </button>
          </div>
          {message && (
            <div className='alert alert-info' role='alert'>
              {message}
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

export {InputCard}
