import React, {useState, useEffect, FC} from 'react'
import axios from 'axios'
import {PageTitle} from '../../../../_designereyes/layout/core'
import {API_URL} from '../../../modules/auth/core/_requests'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import {Checkbox} from 'primereact/checkbox'
import 'primereact/resources/themes/arya-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import './RepricerApp.css'

const RepricerAppPage: FC = () => {
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [search, setSearch] = useState<string>('')
  const [selectedProducts, setSelectedProducts] = useState<any[]>([])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${API_URL}/api/repricer`)

      const enhancedData = response.data.map((item: any) => ({
        ...item,
        abs_min_price_change:
          item.min_price_change !== null ? Math.abs(item.min_price_change) : null,
        abs_max_price_change:
          item.max_price_change !== null ? Math.abs(item.max_price_change) : null,
      }))

      setData(enhancedData)
      setSelectedProducts(enhancedData.map((item) => item.sku))
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const updateRepricer = async () => {
    try {
      setLoading(true)
      const response = await axios.post(`${API_URL}/api/repricer/update`, data)
      console.log('Repricer updated:', response.data)
      fetchData()
    } catch (error) {
      console.error('Error updating repricer:', error)
    } finally {
      setLoading(false)
    }
  }

  const changeBodyTemplate = (rowData: any, field: string, isNew: boolean) => {
    const value = rowData[field]
    if (value === null || value === undefined || value === 0) return ''
    let formattedValue = value.toFixed(2)
    let color = '#ffffff'

    if (value > 0) {
      color = '#28a745'
      if (!isNew) {
        formattedValue = `+${formattedValue}`
      }
    } else if (value < 0) {
      color = '#dc3545'
    }

    return <span style={{color}}>{formattedValue}</span>
  }

  const minPriceChangeBodyTemplate = (rowData: any) => {
    const isNew = !rowData.current_min_price && rowData.new_min_price
    return changeBodyTemplate(rowData, 'min_price_change', isNew)
  }

  const maxPriceChangeBodyTemplate = (rowData: any) => {
    const isNew = !rowData.current_max_price && rowData.new_max_price
    return changeBodyTemplate(rowData, 'max_price_change', isNew)
  }

  const priceBodyTemplate = (rowData: any, field: string) => {
    const value = rowData[field]
    return value ? value.toFixed(2) : ''
  }

  const filteredData = data.filter(
    (item) =>
      item.title?.toLowerCase().includes(search.toLowerCase()) ||
      item.sku?.toLowerCase().includes(search.toLowerCase()) ||
      item.asin?.toLowerCase().includes(search.toLowerCase())
  )

  const checkboxBodyTemplate = (rowData: any) => {
    return (
      <Checkbox
        checked={selectedProducts.includes(rowData.sku)}
        onChange={(e) => {
          if (e.checked) {
            setSelectedProducts([...selectedProducts, rowData.sku])
          } else {
            setSelectedProducts(selectedProducts.filter((sku) => sku !== rowData.sku))
          }
        }}
      />
    )
  }

  return (
    <div className='card m-1 text-light'>
      <div className='card-header d-flex justify-content-between align-items-center custom-header'>
        <h2 className='card-title mb-0'>Repricer</h2>
        <div className='d-flex align-items-center'>
          <input
            type='text'
            placeholder='Search by Title, SKU, or ASIN'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className='form-control search-input'
            style={{marginRight: '0.5rem', width: '250px'}}
          />
          <Button
            label='Update Repricer'
            icon='pi pi-refresh'
            className='p-button-success update-button'
            onClick={updateRepricer}
            disabled={loading}
          />
        </div>
      </div>

      <div className='card-body'>
        <DataTable
          value={filteredData}
          loading={loading}
          paginator
          rows={200}
          scrollable
          scrollHeight='600px'
          sortMode='multiple'
          className='p-datatable-striped custom-datatable'
        >
          <Column body={checkboxBodyTemplate} style={{width: '50px'}}></Column>
          <Column field='title' header='Title' sortable></Column>
          <Column field='sku' header='SKU' sortable></Column>
          <Column field='asin' header='ASIN' sortable></Column>
          <Column
            field='map_price'
            header='MAP Price'
            sortable
            dataType='numeric'
            body={(rowData) => priceBodyTemplate(rowData, 'map_price')}
          ></Column>
          <Column
            field='current_min_price'
            header='Current Min Price'
            sortable
            dataType='numeric'
            body={(rowData) => priceBodyTemplate(rowData, 'current_min_price')}
          ></Column>
          <Column
            field='new_min_price'
            header='New Min Price'
            sortable
            dataType='numeric'
            body={(rowData) => priceBodyTemplate(rowData, 'new_min_price')}
          ></Column>
          <Column
            field='abs_min_price_change'
            header='Min Price Change'
            sortable
            sortField='abs_min_price_change'
            body={minPriceChangeBodyTemplate}
          ></Column>
          <Column
            field='current_max_price'
            header='Current Max Price'
            sortable
            dataType='numeric'
            body={(rowData) => priceBodyTemplate(rowData, 'current_max_price')}
          ></Column>
          <Column
            field='new_max_price'
            header='New Max Price'
            sortable
            dataType='numeric'
            body={(rowData) => priceBodyTemplate(rowData, 'new_max_price')}
          ></Column>
          <Column
            field='abs_max_price_change'
            header='Max Price Change'
            sortable
            sortField='abs_max_price_change'
            body={maxPriceChangeBodyTemplate}
          ></Column>
          <Column
            field='current_unit_cost'
            header='Current Unit Cost'
            sortable
            dataType='numeric'
            body={(rowData) => priceBodyTemplate(rowData, 'current_unit_cost')}
          ></Column>
          <Column
            field='new_unit_cost'
            header='New Unit Cost'
            sortable
            dataType='numeric'
            body={(rowData) => priceBodyTemplate(rowData, 'new_unit_cost')}
          ></Column>
        </DataTable>
      </div>
    </div>
  )
}

export const RepricerAppWrapper: FC = () => {
  return (
    <>
      <PageTitle>Repricer</PageTitle>
      <RepricerAppPage />
    </>
  )
}
