import React, {useState, FC} from 'react'
import {PageTitle} from '../../../../_designereyes/layout/core'
import {useAuth} from '../../../modules/auth'
import {InputCard} from '../../../../_designereyes/partials/widgets'
import {ReportDetails} from '../../../../_designereyes/partials/widgets'

interface ReportData {
  totalAsins: number
  successfulAsins: number
  failedAsins: number
  titlesExceedingLength: number
  descriptionsExceedingLength: number
  bulletPointsQty: number
  bulletsLengthOptimized: number
  brandStoryIncluded: number
  aplusContentIncluded: number
  imagesQtyOptimized: number
  videoShortIncluded: number
  primeAvailable: number
  customerReviewsOptimized: number
  productRatingOptimized: number
}

const RetailReadinessReportPage: FC = () => {
  const [reportData, setReportData] = useState<ReportData | null>(null)

  return (
    <div className='row'>
      <div className='col-md-6'>
        <InputCard setReportData={setReportData} />
      </div>
      <div className='col-md-6'>
        <ReportDetails reportData={reportData} />
      </div>
    </div>
  )
}

export const RetailReadinessReportWrapper: FC = () => {
  const {currentUser} = useAuth()
  return (
    <>
      <PageTitle>Retail Readiness Report Generator</PageTitle>
      {currentUser?.decoded?.vendorName === 'Designer Eyes' && <RetailReadinessReportPage />}
    </>
  )
}
