interface StandardHeader {
  header: string
  description: string
}

const standardHeaders: StandardHeader[] = [
  {
    header: 'No.',
    description:
      'Enter the SKU, unique serial number or identifier for each product. This number helps in tracking and organizing products within the catalog. Example: 4001, 4002, 4003',
  },
  {
    header: 'UPC',
    description:
      'Provide the Universal Product Code associated with the product. The UPC is a 12-digit barcode used extensively for retail packaging in the United States.',
  },
  {
    header: 'Model',
    description:
      'Indicate the model number or name of the product. This information is crucial for distinguishing different products or versions from the same manufacturer.',
  },
  {
    header: 'Vendor Name',
    description:
      'Enter the name of the vendor or supplier providing the product. This helps in identifying and managing different sources of products in the catalog.',
  },
  {
    header: 'Brand Name',
    description:
      'Specify the brand name under which the product is sold. This is essential for brand recognition and for customers who prefer specific brands.',
  },
  {
    header: 'Product Type',
    description:
      'Describe the type or category of the product. For example, Sunglasses, Opitcal, etc. This helps in classifying and sorting products in the catalog.',
  },
  {
    header: 'Bridge Size',
    description:
      'Enter the bridge size of the product, if applicable. This is particularly relevant for products like eyewear, where bridge size is a key measurement.',
  },
  {
    header: 'Product Color Description',
    description:
      "Provide a detailed description of the product's color. Instead of general color terms, use specific shades to give a more accurate representation of the product.",
  },
  {
    header: 'Gender',
    description:
      'Specify the gender for which the product is intended. Options could include Male, Female, Unisex, etc. This helps in targeting the right customer demographic.',
  },
  {header: 'Item Category Code', description: 'description'},
  {header: 'Shape Description', description: 'description'},
  {header: 'Temple Size', description: 'description'},
  {header: 'Product Group Code', description: 'description'},
  {header: 'Color Code', description: 'description'},
  {header: 'Style Name', description: 'description'},
  {header: 'Wholesale Price', description: 'description'},
  {header: 'Product Color Code', description: 'description'},
  {header: 'Amazon Price', description: 'description'},
  {header: 'ASIN', description: 'description'},
  {header: 'Base Unit of Measure', description: 'description'},
  {header: 'Blocked', description: 'description'},
  {header: 'Brand', description: 'description'},
  {header: 'Bullets', description: 'description'},
  {header: 'Check', description: 'description'},
  {header: 'Classification', description: 'description'},
  {header: 'Classification Name', description: 'description'},
  {header: 'Collection', description: 'description'},
  {header: 'Consumer Package Weight', description: 'description'},
  {header: 'Consumer Package Weight Uom', description: 'description'},
  {header: 'Cost is Adjusted', description: 'description'},
  {header: 'Costing Method', description: 'description'},
  {header: 'Country Of Origin', description: 'description'},
  {header: 'Description', description: 'description'},
  {header: 'Duty Class', description: 'description'},
  {header: 'EBay Price', description: 'description'},
  {header: 'Final Discount Allowed', description: 'description'},
  {header: 'Final Ticket Price', description: 'description'},
  {header: 'First Receipt Date', description: 'description'},
  {header: 'Flag Status', description: 'description'},
  {header: 'Frame Color', description: 'description'},
  {header: 'Frame Material', description: 'description'},
  {header: 'Gen. Prod. Posting Group', description: 'description'},
  {header: 'Gender 1', description: 'description'},
  {header: 'Gender Code', description: 'description'},
  {header: 'Geo Fit', description: 'description'},
  {header: 'Indirect Cost %', description: 'description'},
  {header: 'Inventory Posting Group', description: 'description'},
  {header: 'Item Disc. Group', description: 'description'},
  {header: 'Keywords', description: 'description'},
  {header: 'Last Date Modified', description: 'description'},
  {header: 'Last Direct Cost', description: 'description'},
  {header: 'Last Receipt Date', description: 'description'},
  {header: 'Lead Time Calculation', description: 'description'},
  {header: 'Lens Base', description: 'description'},
  {header: 'Lens Color', description: 'description'},
  {header: 'Lens Color Code', description: 'description'},
  {header: 'Lens Color Description', description: 'description'},
  {header: 'Lens Curv', description: 'description'},
  {header: 'Lens Material', description: 'description'},
  {header: 'Lens Material Code', description: 'description'},
  {header: 'Lens Material Description', description: 'description'},
  {header: 'shape', description: 'description'},
  {header: 'Lens Type', description: 'description'},
  {header: 'Lens Width', description: 'description'},
  {header: 'Map Price', description: 'description'},
  {header: 'Max Discount', description: 'description'},
  {header: 'Max Discount Override', description: 'description'},
  {header: 'MSRP', description: 'description'},
  {header: 'Outlet Price', description: 'description'},
  {header: 'Overhead Rate', description: 'description'},
  {header: 'Perm Price', description: 'description'},
  {header: 'Polarized', description: 'description'},
  {header: 'Price Unit Of Measure', description: 'description'},
  {header: 'Price/Profit Calculation', description: 'description'},
  {header: 'Product Code', description: 'description'},
  {header: 'Product Code Description', description: 'description'},
  {header: 'Product Group Description', description: 'description'},
  {header: 'Profit %', description: 'description'},
  {header: 'Purch. Unit of Measure', description: 'description'},
  {header: 'Qty. on Purch. Order', description: 'description'},
  {header: 'Qty. on Purch. Return', description: 'description'},
  {header: 'Qty. on Sales Order', description: 'description'},
  {header: 'Qty. on Sales Return', description: 'description'},
  {header: 'Quantity on Hand', description: 'description'},
  {header: 'Release Date', description: 'description'},
  {header: 'Replenishment System', description: 'description'},
  {header: 'Retail Price', description: 'description'},
  {header: 'Rxable', description: 'description'},
  {header: 'Sales Unit of Measure', description: 'description'},
  {header: 'Search Description', description: 'description'},
  {header: 'Season', description: 'description'},
  {header: 'Selection Code Description', description: 'description'},
  {header: 'Shelf No.', description: 'description'},
  {header: 'Shipping Unit of Measure', description: 'description'},
  {header: 'Size', description: 'description'},
  {header: 'Standard Cost', description: 'description'},
  {header: 'Status', description: 'description'},
  {header: 'Status Description', description: 'description'},
  {header: 'Stock Category', description: 'description'},
  {header: 'Story/Family Name', description: 'description'},
  {header: 'Style Number', description: 'description'},
  {header: 'Style Type', description: 'description'},
  {header: 'Tariff No.', description: 'description'},
  {header: 'Unit Cost', description: 'description'},
  {header: 'Unit Price', description: 'description'},
  {header: 'Used For', description: 'description'},
  {header: 'Vendor Item No.', description: 'description'},
  {header: 'Vendor No.', description: 'description'},
  {header: 'Vendor Part Description', description: 'description'},
  {header: 'Vendor Part Number', description: 'description'},
  {header: 'Web Price', description: 'description'},
  {header: 'Year', description: 'description'},
]

export default standardHeaders
