// src/app/pages/dashboard/Apps/InvoiceProcessingWrapper.tsx

import React, { useState, useRef, useEffect, FC } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { API_URL } from '../../../modules/auth/core/_requests';
import { PageTitle } from '../../../../_designereyes/layout/core';
import 'primereact/resources/themes/saga-blue/theme.css';       // PrimeReact Theme
import 'primereact/resources/primereact.min.css';               // PrimeReact Core CSS
import 'primeicons/primeicons.css';                             // PrimeReact Icons
import { Toast } from 'primereact/toast';                       // Import PrimeReact's Toast
import './InvoiceProcessorWrapper.css';
import { Collapse } from 'react-bootstrap';

// Define supported invoice formats
const FORMAT_OPTIONS = [
  { key: 'Luxottica', label: 'Luxottica' },
  { key: 'Marcolin', label: 'Marcolin' },
  { key: 'Marchon', label: 'Marchon' },
] as const;

//type SupportedFormat = typeof FORMAT_OPTIONS[number];
//Buid did not work!
// Define address options
const ADDRESS_OPTIONS = [
  {
    key: 'NEW_AMAZON',
    address: `BEN ISH CHAI LLC
110 SE 6TH ST. STE 1700
FT LAUDERDALE, FL 33301`,
  },
  {
    key: 'OLD_AMAZON',
    address: `ECAMZ SALES, LLC
12717 W SUNRISE BLVD #511
SUNRISE, FL 33323`,
  },
];

const InvoiceProcessorPage: FC = () => {
  const [upcList, setUpcList] = useState<string>('');
  const [invoiceFiles, setInvoiceFiles] = useState<FileList | null>(null);
  const [processedFileUrl, setProcessedFileUrl] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string>('processed_invoices.zip');
  const [selectedAddress, setSelectedAddress] = useState<{
    key: string;
    address: string;
  }>(ADDRESS_OPTIONS[0]);
  const [selectedFormat, setSelectedFormat] = useState<{
    key: string;
    label: string;
  }>(FORMAT_OPTIONS[0]);
  const [showUpcInput, setShowUpcInput] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [isDownloading, setIsDownloading] = useState<boolean>(false); // New state

  const toast = useRef<Toast>(null); // PrimeReact Toast ref
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Use useEffect to show/hide UPC input based on selected format
  useEffect(() => {
    if (selectedFormat.key === 'Luxottica') {
      // Allow UPC input to be shown or hidden by the user
    } else {
      // Hide UPC input when format is not Luxottica
      setShowUpcInput(false);
      // Clear UPC list
      setUpcList('');
    }
  }, [selectedFormat]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const validFiles: File[] = [];
      const invalidFiles: string[] = [];

      Array.from(event.target.files).forEach((file) => {
        if (file.type === 'application/pdf' && file.size <= 10 * 1024 * 1024) {
          validFiles.push(file);
        } else {
          invalidFiles.push(file.name);
        }
      });

      if (invalidFiles.length > 0) {
        toast.current?.show({
          severity: 'error',
          summary: 'Invalid Files',
          detail: `The following files are invalid (only PDFs <= 10MB are allowed):\n${invalidFiles.join(
            '\n'
          )}`,
          life: 10000,
        });
      }

      if (validFiles.length > 0) {
        const fileList = new DataTransfer();
        validFiles.forEach((file) => fileList.items.add(file));
        setInvoiceFiles(fileList.files);
        setProcessedFileUrl(null); // Reset to enable "Process" button
        setFileName('processed_invoices.zip');
      } else {
        setInvoiceFiles(null);
      }
    }
  };

  const handleProcessInvoices = async () => {
    if (!invoiceFiles || invoiceFiles.length === 0) {
      toast.current?.show({
        severity: 'error',
        summary: 'No Files Selected',
        detail: 'Please select at least one PDF invoice to process.',
        life: 5000,
      });
      return;
    }

    let upcs: string[] = [];

    if (selectedFormat.key === 'Luxottica') {
      upcs = upcList
        .split('\n')
        .map((value) => value.trim())
        .filter(Boolean);
    } else {
      // For other formats, clear the UPC list
      upcs = [];
    }

    const timestamp = generateTimestamp();

    const formData = new FormData();
    Array.from(invoiceFiles).forEach((file) => formData.append('invoices', file));
    formData.append('selectedAddress', selectedAddress.address);
    formData.append('selectedFormat', selectedFormat.key); // Add selectedFormat
    if (upcs.length > 0) {
      formData.append('upcList', JSON.stringify(upcs));
    } else {
      // Ensure upcList is sent as an empty array
      formData.append('upcList', JSON.stringify([]));
    }

    try {
      setLoading(true);
      setProgress(0);

      const pythonApiUrl = `${API_URL}/api/process-invoices`; // Update the endpoint as needed

      const response = await axios.post(pythonApiUrl, formData, {
        headers: {
          // **Do not include '...formData.getHeaders()' as it's invalid in the browser.**
          // Let Axios set the headers automatically.
        },
        responseType: 'blob', // Expecting a ZIP file
        timeout: 300000, // 5 minutes timeout
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          }
        },
      });

      // Extract filename from response headers if available
      const disposition = response.headers['content-disposition'];
      let filename = `processed_invoices_${timestamp}.zip`;
      if (disposition && typeof disposition === 'string') {
        const match = disposition.match(/filename="?(.+)"?/);
        if (match && match[1]) {
          filename = match[1];
        }
      }

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/zip' })
      );
      setProcessedFileUrl(url);
      setFileName(filename);

      toast.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Invoices processed successfully. You can now download your files.',
        life: 5000,
      });

      // Reset file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error: any) {
      console.error('Error processing the invoices:', error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.current?.show({
          severity: 'error',
          summary: 'Processing Error',
          detail: `Error: ${error.response.data.error}`,
          life: 5000,
        });
      } else {
        toast.current?.show({
          severity: 'error',
          summary: 'Processing Error',
          detail: 'An error occurred while processing the invoices. Please try again.',
          life: 5000,
        });
      }
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  const handleDownload = () => {
    if (processedFileUrl) {
      setIsDownloading(true); // Disable the download button
      saveAs(processedFileUrl, fileName);
      window.URL.revokeObjectURL(processedFileUrl);
      setProcessedFileUrl(null);
      setFileName('processed_invoices.zip');
      toast.current?.show({
        severity: 'info',
        summary: 'Download Started',
        detail: 'Your download is starting.',
        life: 3000,
      });

      // Clear invoiceFiles to disable the "Process" button
      setInvoiceFiles(null);

      // Re-enable the download button after a short delay
      setTimeout(() => {
        setIsDownloading(false);
      }, 3000); // Adjust the delay as needed
    }
  };

  const generateTimestamp = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  };

  return (
    <div className='card m-1 text-light position-relative pt-6'>
      {/* PrimeReact Toast Component */}
      <Toast ref={toast} position="bottom-left" />

      <div className='card-header d-flex justify-content-between align-items-center'>
        <h2 className='card-title mb-0'>Invoice Processor</h2>
      </div>

      <div className='card-body'>
        {/* Invoice Format Selection */}
        <div className='my-4'>
          <label className='form-label text-white mb-2'>Select Invoice Format:</label>
          <div className='d-flex flex-column flex-md-row mt-0 format-selection'>
            {FORMAT_OPTIONS.map((option) => (
              <button
                key={option.key}
                type='button'
                className={`btn btn-medium m-2 format-button btn-bordered color-white ${
                  selectedFormat.key === option.key ? 'btn-primary' : 'btn-outline-primary'
                }`}
                onClick={() => setSelectedFormat(option)}
                disabled={loading}
                aria-label={`Select ${option.label} Invoice Format`}
                style={{
                  border: '1px solid var(--bs-gray-300)',
                  fontSize: '12px',
                  whiteSpace: 'normal',
                  lineHeight: '1.2',
                }}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>

        {/* Address Selection */}
        <div className='my-4'>
          <label className='form-label text-white mb-2'>Select Address:</label>
          <div className='d-flex flex-column flex-md-row mt-0 address-forms'>
            {ADDRESS_OPTIONS.map((option) => (
              <button
                key={option.key}
                type='button'
                className={`btn btn-medium m-2 address-button btn-bordered color-white ${
                  selectedAddress.key === option.key ? 'btn-primary' : 'btn-outline-primary'
                }`}
                onClick={() => setSelectedAddress(option)}
                disabled={loading}
                aria-label={`Select ${option.key} Address`}
                style={{
                  border: '1px solid var(--bs-gray-300)',
                  fontSize: '12px',
                  whiteSpace: 'normal',
                  lineHeight: '1.2',
                }}
              >
                {option.address}
              </button>
            ))}
          </div>
        </div>

        {/* File Upload */}
        <div className='my-3'>
          <div className='row flex align-items-end'>
            <div className='col-12 col-sm-12 col-md-8'>
              <label htmlFor='invoiceFiles' className='form-label text-white'>
                Upload Invoice Files:
              </label>
              <div className='d-flex justify-content-center w-90 m-2 mb-0'>
                <input
                  type='file'
                  className='form-control'
                  multiple
                  onChange={handleFileChange}
                  accept='application/pdf'
                  disabled={loading}
                  id='invoiceFiles'
                  aria-label='Upload PDF invoice files'
                  ref={fileInputRef}
                />
              </div>
            </div>
            <div className='m-2 m-sm-0 col-12 col-sm-12 col-md-4'>
              <button
                type='button'
                className={`w-90 btn ${processedFileUrl ? 'btn-primary' : 'btn-secondary'}`}
                onClick={handleDownload}
                aria-label='Processed Invoices'
                disabled={!processedFileUrl || isDownloading}
              >
                <i className='pi pi-download' style={{ marginRight: '8px' }}></i>
                {isDownloading ? 'Downloading...' : 'Processed Invoices'}
              </button>
            </div>
          </div>
        </div>

        {/* UPC Input Collapsible Section */}
        {selectedFormat.key === 'Luxottica' && (
          <div className='my-4 p-2'>
            <div>
              <button
                type='button'
                className='btn w-100 d-flex align-items-center justify-content-start no-hover'
                onClick={() => setShowUpcInput(!showUpcInput)}
                aria-expanded={showUpcInput}
                aria-controls='upcInput'
                disabled={loading}
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  padding: '0.75rem 1.25rem',
                  paddingLeft: '0',
                  textAlign: 'left',
                }}
              >
                <span>{showUpcInput ? 'Hide UPC Input' : 'Add UPCs (Optional)'}</span>
                <i
                  style={{ marginLeft: '8px' }}
                  className={`pi ${showUpcInput ? 'pi-chevron-up' : 'pi-chevron-down'}`}
                ></i>
              </button>
              <Collapse in={showUpcInput}>
                <div
                  id='upcInput'
                  className='w-sm-100 w-50 w-md-50'
                  style={{ padding: '5px' }}
                >
                  <label htmlFor='upcList' className='form-label text-dark'>
                    Enter UPCs (one per line):
                  </label>
                  <div className='d-flex justify-content-center'>
                    <textarea
                      className='form-control'
                      id='upcList'
                      rows={5}
                      value={upcList}
                      onChange={(e) => setUpcList(e.target.value)}
                      disabled={loading}
                      placeholder='e.g., UPC12345'
                      aria-label='List of UPCs, one per line (optional)'
                    />
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        )}

        {/* Process Button */}
        <div className='mb-5'>
          <button
            className={`w-90 btn ${
              loading || !invoiceFiles || processedFileUrl ? 'btn-secondary' : 'btn-primary'
            } my-1 mx-2`}
            onClick={handleProcessInvoices}
            disabled={loading || !invoiceFiles || !!processedFileUrl}
            aria-label='Process Invoices'
          >
            {loading ? 'Processing...' : 'Process and Prepare for Download'}
          </button>
        </div>

        {/* Progress Bar */}
        {loading && (
          <div className='progress my-3' aria-label='Processing progress'>
            <div
              className='progress-bar progress-bar-striped progress-bar-animated smooth-progress-bar'
              role='progressbar'
              style={{ width: `${progress}%` }}
              aria-valuenow={progress}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              {Math.floor(progress)}%
            </div>
          </div>
        )}

        {/* Selected Files List */}
        {invoiceFiles && invoiceFiles.length > 0 && (
          <div className='my-3 selected-files-container'>
            <h5>Selected Files:</h5>
            <ul>
              {Array.from(invoiceFiles).map((file, index) => (
                <li className='text-white' key={index}>
                  <span>{file.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export const InvoiceProcessorWrapper: FC = () => {
  return (
    <>
      <PageTitle>Invoice Processor</PageTitle>
      <InvoiceProcessorPage />
    </>
  );
};

export default InvoiceProcessorWrapper;
